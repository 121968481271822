.global-error {
  position: absolute;
  left: 16px;
  bottom: 16px;
  transform: translateY(calc(100% + 20px));
  transition: transform .3s;
  z-index: 9999;

  &.show {
    transform: translateY(0);
  }

  p {
    margin: 0;
  }
}
