.bookingForm-passengers {
  display: flex;
  flex-direction: column;
  gap: 20px;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  background-color: $gray-200;
  flex-wrap: wrap;
  margin-top: calc(-1* var(--bs-gutter-y));
  margin-right: calc(-0.5* var(--bs-gutter-x));
  margin-left: calc(-0.5* var(--bs-gutter-x));
  padding: 20px 0;
  padding-right: calc(var(--bs-gutter-x)* 0.5);
  padding-left: calc(var(--bs-gutter-x)* 0.5);

  h5 {
    margin-bottom: 0;
  }
}

.bookingForm-passengerItem {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 0.25rem;
  background-color: $white;
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.03);
}

.bookingForm-passengerLabel {
  margin-bottom: 1rem;
  color: $gray-600;
}
