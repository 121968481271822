[data-skin="dark"] {
  ////////// Accordion //////////
  .accordion-item {
    background-color: inherit;
    border-color: $border-color-dark;
  }

  .accordion-button {
    background-color: inherit;
    color: inherit;

    &:not(.collapsed) {
      background-color: $dark-highlight;
      box-shadow: inset 0 -1px 0 $border-color-dark;
    }
  }

  .accordion-custom {
    .accordion-item:not(:first-of-type) {
      border-top-color: $border-color-dark;
    }

    .accordion-button {
      background-color: inherit;

      &:not(.collapsed) {
        background-color: $dark-highlight;
      }
    }

    .accordion-collapse {
      background-color: $dark-highlight;
    }
  }

  .accordion-primary {
    .accordion-button:not(.collapsed) {
      background-color: transparent;
    }
  }

  .avatar.online,
  .avatar.offline {
    &::after {
      box-shadow: 0 0 0 1.5px $dark-highlight;
    }
  }

  ////////// Buttons //////////
  .btn-close {
    filter: invert(1);
  }

  ////////// Card //////////
  .card {
    background-color: $dark-highlight;
    border-color: $border-color-dark;
  }

  .card-primary {
    background-color: $primary;
  }

  .card-secondary {
    background-color: $secondary;
  }

  .card-success {
    background-color: $success;
  }

  .card-warning {
    background-color: $warning;
  }

  .card-danger {
    background-color: $danger;
  }

  .card-info {
    background-color: $info;
  }

  .card-dark .card-header {
    border-bottom-color: $border-color-dark;
  }

  .card-light {
    background-color: $light;
    color: $body-color;

    .card-header {
      border-bottom-color: $gray-300;
    }
  }

  .card-header {
    background-color: lighten($dark-highlight, 2%);
    border-bottom-color: $border-color-dark;
  }

  .card-header-tabs .nav-link.active {
    background-color: $dark-highlight;
    border-color: $border-color-dark;
    border-bottom-color: transparent;
    color: #fff;
  }

  .card-footer {
    background-color: lighten($dark-highlight, 2%);
    border-top-color: $border-color-dark;
  }

  ////////// Divider //////////
  .divider::before,
  .divider::after {
    background-color: $border-color-dark;
  }

  ////////// Dropdown /////////
  .dropdown-menu {
    border-color: transparent;
    background-color: lighten($dark-highlight, 3%);
    color: inherit;
    box-shadow: 0 2px 10px rgba(#000, 0.1),
    0 10px 20px rgba(#000, 0.1);
  }

  .dropdown-item {
    color: rgba(#fff, .65);

    &:hover,
    &:focus {
      background-color: $dark-highlight;
      color: #fff;
    }

    &.disabled {
      opacity: .6;
    }

    &.active:hover,
    &.active:focus {
      background-color: $primary;
    }
  }

  .dropdown-header {
    color: rgba(#fff, .3);
  }

  .dropdown-divider {
    opacity: 0.06;
  }

  ////////// Forms //////////
  .form-control {
    background-color: $dark-highlight;
    border-color: lighten($border-color-dark, 3%);
    color: rgba(#fff, .65);

    &:focus {
      box-shadow: none;
      background-color: lighten($dark-highlight, 3%);
      border-color: lighten($border-color-dark, 3%);
      color: rgba(#fff, .65);
    }

    &:disabled,
    &[readonly] {
      background-color: $dark-primary;
    }

    &::file-selector-button,
    &::-webkit-file-upload-button {
      background-color: lighten($dark-highlight, 3%);
      color: #fff;
    }

    &:hover:not(:disabled):not([readonly])::file-selector-button {
      background-color: $dark-highlight;
    }

    &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
      background-color: $dark-highlight;
    }

    &::placeholder {
      color: rgba(#fff, .3);
    }
  }

  .form-select {
    background-color: $dark-highlight;
    border-color: lighten($border-color-dark, 3%);
    color: rgba(#fff, .65);

    &:focus {
      box-shadow: none;
      border-color: lighten($border-color-dark, 6%);
    }

    &:disabled {
      background-color: $dark-primary;
    }
  }

  .form-check-input {
    border-color: lighten($border-color-dark, 3%);
    background-color: $dark-primary;

    &:focus {
      border-color: lighten($border-color-dark, 6%);
    }
  }

  .form-switch .form-check-input {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba(255,255,255,0.45)'/></svg>")
  }

  .form-range {
    &::-webkit-slider-runnable-track,
    &::-moz-range-track {
      background-color: $border-color-dark;
    }
  }

  .input-group-text {
    background-color: lighten($dark-highlight, 1.5%);
    border-color: lighten($border-color-dark, 3%);
    color: inherit;
  }

  .form-search {
    background-color: $dark-highlight;
    border: 1px solid lighten($border-color-dark, 3%);
    box-shadow: none;

    &.onfocus {
      background-color: lighten($dark-highlight, 3%);
    }
  }

  ////////// Images //////////
  .img-thumbnail {
    background-color: lighten($dark-highlight, 3%);
    border-color: lighten($border-color-dark, 3%);
  }

  ////////// List Group //////////
  .list-group-item {
    border-color: $border-color-dark;
    background-color: transparent;
    color: inherit;

    &.disabled {
      background-color: $dark-primary;
      color: rgba(#fff, .3);
    }
  }

  ////////// Marker //////////
  .marker {
    background-color: $dark-primary;
    color: inherit;
  }

  .marker-ribbon {
    background-color: $dark-primary;
    border-color: lighten($border-color-dark, 3%);

    &.top-left::before {
      border-right-color: lighten($border-color-dark, 3%);
      left: -11px;
    }

    &.top-right::before {
      border-left-color: lighten($border-color-dark, 3%);
      right: -11px;
    }
  }

  ////////// Modals //////////
  .modal-content {
    background-color: lighten($dark-highlight, 3%);
  }

  .modal-header {
    border-bottom-color: lighten($border-color-dark, 3%);
  }

  .modal-title {
    color: #fff;
  }

  .modal-footer {
    border-top-color: lighten($border-color-dark, 3%);
  }

  ////////// Navs //////////
  .nav-sidebar .nav-link:not(.active) {
    color: inherit;

    &:hover,
    &:focus {
      background-color: $dark-highlight;
      color: inherit;
    }
  }

  .navbar-light {
    .navbar-brand {
      color: #fff;
    }

    .navbar-nav .nav-link {
      color: inherit;

      &:hover, &:focus {
        color: #fff;
      }
    }

    .navbar-nav .nav-link.active {
      color: #fff;
    }
  }

  .nav-icon .nav-link {
    &:hover,
    &:focus {
      color: #fff;
    }
  }

  .nav-line {
    border-bottom-color: $border-color-dark;

    .nav-link {
      color: inherit;

      &:hover,
      &:focus {
        color: rgba(#fff, .75);
      }

      &.active {
        color: #fff;
      }
    }
  }

  .nav-classic {
    .nav-link + .nav-link {
      border-top-color: lighten($border-color-dark, 5%);
    }

    .badge {
      color: rgba(#fff, .65);
    }
  }

  ////////// Off Canvas //////////
  .offcanvas {
    background-color: $dark-highlight;
  }

  .offcanvas-title {
    color: #fff;
  }

  ////////// Pagination //////////
  .page-link {
    background-color: $dark-primary;
    border-color: $border-color-dark;

    &:hover,
    &:focus {
      background-color: $dark-highlight;
      border-color: lighten($border-color-dark, 3%);
    }
  }

  .page-item.disabled {
    .page-link {
      background-color: $dark-highlight;
      border-color: $border-color-dark;
      color: rgba(#fff, .3);
    }
  }

  .pagination-filled {
    .page-item {
      &.disabled .page-link {
        background-color: $dark-primary;
      }

      &:not(.active) .page-link:hover {
        background-color: lighten($dark-highlight, 5%);
      }
    }

    .page-link {
      border-width: 0;
      background-color: $dark-highlight;
      color: $secondary;
    }
  }

  ////////// Popover //////////
  .popover {
    background-color: $dark-highlight;
    border-color: lighten($border-color-dark, 3%);
  }

  .bs-popover-top > .popover-arrow {
    &::before {
      border-top-color: lighten($border-color-dark, 3%);
    }

    &::after {
      border-top-color: $dark-highlight;
    }
  }

  .bs-popover-end > .popover-arrow {
    &::before {
      border-right-color: lighten($border-color-dark, 3%);
    }

    &::after {
      border-right-color: $dark-highlight;
    }
  }

  .bs-popover-bottom > .popover-arrow {
    &::before {
      border-bottom-color: lighten($border-color-dark, 3%);
    }

    &::after {
      border-bottom-color: $dark-highlight;
    }
  }

  .bs-popover-start > .popover-arrow {
    &::before {
      border-left-color: lighten($border-color-dark, 3%);
    }

    &::after {
      border-left-color: $dark-highlight;
    }
  }

  .popover-header {
    background-color: $dark-highlight;
    border-bottom-color: lighten($border-color-dark, 3%);
    color: #fff;
  }

  .popover-body {
    color: inherit;
  }

  ////////// Progress //////////
  .progress {
    background-color: $border-color-dark;
  }

  ////////// Toast //////////
  .toast {
    background-color: rgba(lighten($dark-highlight, 5%), .85);
  }

  .toast-header {
    background-color: rgba(lighten($dark-highlight, 8%), .85);
  }

  ///// Tables //////////
  .table {
    color: inherit;

    th, td {
      border-color: $border-color-dark;
    }

    &:not(.table-borderless) {
      > :not(:first-child) {
        border-top-color: $border-color-dark;
      }
    }
  }

  .table-striped {
    > tbody > tr:nth-of-type(2n+1) > * {
      box-shadow: inset 0 0 0 9999px rgba($border-color-dark, .6);
      color: inherit;
    }
  }

  .table-hover {
    > tbody > tr:hover > * {
      box-shadow: inset 0 0 0 9999px rgba($border-color-dark, .85);
      color: inherit;
    }
  }

  .table-active {
    color: inherit;

    td, th {
      box-shadow: inset 0 0 0 9999px rgba($border-color-dark, .85);
    }
  }

  .table-primary,
  .table-secondary,
  .table-success,
  .table-warning,
  .table-danger,
  .table-info {
    tbody th,
    tbody td {
      border-color: $dark-primary;
    }
  }

  .table-primary {
    td, th {
      background-color: $primary;
    }
  }

  .table-secondary {
    td, th {
      background-color: $secondary;
    }
  }

  .table-success {
    td, th {
      background-color: $success;
    }
  }

  .table-warning {
    td, th {
      background-color: $warning;
    }
  }

  .table-danger {
    td, th {
      background-color: $danger;
    }
  }

  .table-info {
    td, th {
      background-color: $info;
    }
  }

  .table-bordered {
    border-color: $border-color-dark;
  }

  .gridjs-pages button:disabled {
    border-color: $border-color-dark;
  }

  .gridjs-input {
    background-color: $dark-primary;
    border-color: $border-color-dark;
    color: #fff;

    &:focus {
      box-shadow: none;
      background-color: $dark-highlight;
      border-color: lighten($border-color, 3%);
      color: #fff;
    }

    &::placeholder {
      opacity: .6;
    }
  }
}
