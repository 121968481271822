
.finance-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  @include gradient-y(#fff, rgba(255, 255, 255, 0));
}

.finance-value {
  font-family: $ff-numerals;
  font-size: 32px;
  letter-spacing: -1px;
  color: $dark;
  text-indent: -2px;

  @include media-breakpoint-up(xl) {
    font-size: 40px;
  }

  span {
    font-weight: 300;
    color: $body-color;
  }
}

.finance-subvalue {
  display: flex;
  align-items: center;
  grid-gap: 3px;
  font-size: 20px;

  span {
    font-family: $ff-numerals;
    letter-spacing: -.2px;
  }

  small {
    font-size: 18px;
    font-weight: 400;
    align-self: flex-end;
    margin-bottom: .5px;
    color: $body-color;
  }
}

.nav-finance-one {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  font-family: $ff-numerals;
  font-size: $font-size-sm;

  a {
    color: $secondary;
    letter-spacing: -.2px;

    &.active {
      font-weight: $font-weight-medium;
      color: $primary;
      text-decoration: underline;
    }

    + a {
      margin-left: 10px;
    }
  }
}

.card-wallet {
  position: relative;
  overflow: hidden;
  height: 240px;

  @include media-breakpoint-up(md) {
    height: 100%;
  }

  .card-body {
    padding: 23px 25px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 5;

    h5 {
      font-weight: $font-weight-medium;
      color: $dark;
    }
  }

  .card-title {
    font-size: 12px;
    font-family: $ff-numerals;
    display: block;
  }

  .finance-icon {
    position: absolute;
    top: 5px;
    right: 24px;
    display: flex;
    align-items: center;

    i {
      line-height: 1;
      color: $primary;

      &:first-child {
        font-size: 42px;
      }

      &:last-child {
        font-size: 58px;
      }

      + i {
        margin-left: 5px;
      }
    }
  }

  .card-value {
    color: $dark;
    letter-spacing: -.5px;
    text-indent: -1px;

    span {
      font-weight: 300;
    }
  }

  .badge-dot {
    background-color: $dark;
    color: $dark;
  }

  .flot-chart {
    width: calc(100% + 18px);
    height: 200px;
    position: relative;
    left: -10px;

    @include media-breakpoint-up(sm) {
      height: 230px;
    }
    @include media-breakpoint-up(md) {
      height: 100%;
    }
    @include media-breakpoint-up(xl) {
      height: 100%;
    }
  }

  .apex-chart-two {
    display: flex;
    align-items: flex-end;
  }
}

.progress-finance {
  height: 80px;
  border-radius: 2px;

  .progress-bar {
    font-size: $font-size-base;
  }

  .progress-bar:nth-child(2) {
    background-color: $color-ui-02;
  }

  .progress-bar:last-child {
    background-color: $color-ui-03;
  }
}

.finance-peity-donut {
  height: 290px;

  @include media-breakpoint-up(xl) {
    height: 100%;
  }
}

.finance-donut-value {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: $font-weight-semibold;
    font-family: $ff-numerals;
    letter-spacing: -1px;
    margin-bottom: 0;
  }

  p {
    font-family: $ff-numerals;
    margin-bottom: 0;
    color: $secondary;
  }
}

.finance-item-circle {
  margin: auto;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: $gray-200;

  @include media-breakpoint-up(xl) {
    width: 150px;
    height: 150px;
  }

  h1 {
    font-family: $ff-numerals;
    font-weight: $font-weight-semibold;
    color: $dark;
    margin-bottom: 0;
  }

  label {
    display: block;
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    color: $dark;
  }
}

.apex-chart-two {
  position: relative;
  left: -15px;
  width: calc(100% + 35px);
}

.apex-chart-three {
  position: relative;
  left: -25px;
  width: calc(100% + 40px);

  .apexcharts-xaxis-label {
    position: relative;
    transform: translateY(-8px);
  }
}

.apex-donut-two {
  display: flex;
  align-items: center;
  justify-content: center;

  .apexcharts-canvas {
    transform: scale(1.1);
  }
}
