
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  border-color: $gray-400 !important;
  font-family: $font-family-sans-serif;
}

.react-datepicker__header {
  background-color: $gray-100;
  border-bottom-color: $gray-600;
}

.react-datepicker__navigation-icon {
  &::before {
    top: 12px;
  }
}

.react-datepicker__current-month {
  font-size: 13px;
  text-transform: uppercase;
}

.react-datepicker__day--outside-month {
  opacity: .6;
}

.react-datepicker__day-name {
  width: 32px;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: $primary !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: $primary !important;
}

.react-datepicker__header {
  background-color: $info !important;
  border-bottom-color: $gray-400 !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  fill: $info !important;
  color: $gray-400 !important;
  stroke: none !important;
}

.react-datepicker__time-container {
  border-left-color: $gray-400 !important;
}
