
.contact-panel {
  position: relative;
  height: calc(100vh - 145px);
  display: flex;

  @include media-breakpoint-up(lg) {
    height: calc(100vh - 155px);
  }

  &.sidebar-show {
    @include media-breakpoint-down(xl) {
      overflow: hidden;

      .contact-sidebar {
        left: 0;
        opacity: 1;
        visibility: visible;
      }

      .contact-body {
        transform: translateX(226px);
      }
    }
  }

  &.contact-show {
    @include media-breakpoint-down(md) {
      .contact-list {
        display: none;
      }
      .contact-content {
        display: block;
      }
    }
  }
}

.contact-sidebar {
  width: 210px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -234px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  flex-shrink: 0;
  @include transition(all 0.25s);

  @include media-breakpoint-up(xl) {
    position: relative;
    display: block;
    padding-right: 12px;
    margin-right: 12px;
    opacity: 1;
    visibility: visible;
    left: 0;
  }

  .nav-sidebar {
    .nav-link {
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 4px;

      &:hover {
        background-color: $gray-200;
      }

      &.active {
        background-color: rgba($primary, .08);
      }

      + .nav-link {
        margin-top: 1px;
      }

      small {
        margin-left: auto;
        font-family: $font-family-sans-serif;
        font-size: 12px;
        font-weight: 400;
        color: $gray-500;
      }
    }
  }

  hr {
    opacity: .1;
  }
}

.btn-contact-new {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 16px;
    line-height: 1.2;
    margin-right: 2px;
    margin-top: .5px;
  }
}

.contact-body {
  flex: 1;
  height: 100%;
  background-color: #fff;
  @include shadow-dreamy($gray-400);
  border-radius: 6px;
  position: relative;
  @include transition(all 0.25s);
}

.contact-list {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  @include media-breakpoint-up(md) {
    width: 280px;
    border-right: 1px solid rgba($border-color, .6);
  }
}

.contact-list-header {
  height: 60px;
  background-color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: inherit;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid rgba($border-color, .6);
  display: flex;
  align-items: center;

  @include media-breakpoint-up(xl) {
    padding-left: 20px;
  }

  .sidebar-title {
    font-size: 16px;
    font-weight: $font-weight-semibold;
    color: $dark;
    margin-bottom: 0;
  }
}

.contact-menu {
  display: flex;
  align-items: center;
  font-size: 21px;
  margin-right: 10px;
  color: $secondary;
}

.contact-list-body {
  height: calc(100% - 60px);
  padding: 10px;
  position: relative;
  overflow: hidden;

  .sidebar-label:first-child {
    margin-top: 5px;
  }
}

.contact-group {
  @extend .chat-group;
}

.contact-item {
  @extend .chat-item;
  position: relative;

  &:hover,
  &:focus {
    .nav-icon {
      display: flex;
    }
  }

  .nav-icon {
    position: absolute;
    right: 10px;
    display: none;
  }

  .nav-link {
    font-size: 16px;
    color: $gray-500;
    padding: 0;

    + .nav-link {
      margin-left: 5px;
    }

    &:hover {
      color: $gray-700;
    }
  }
}

.contact-item-body {
  @extend .chat-item-body;

  span {
    font-family: $font-family-sans-serif;
    font-size: $font-size-sm;
  }
}

.contact-content {
  display: none;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 20px;

  @include media-breakpoint-up(md) {
    margin-left: 280px;
    display: block;
  }

  .avatar {
    width: 100px;
    height: 100px;
  }

  .avatar-initial {
    font-size: 52px;
    font-weight: 300;
  }

  hr {
    opacity: .12;
  }
}

.contact-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: $secondary;

  &:hover {
    color: $gray-700;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}
